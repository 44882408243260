<template>
    <div>
      <!-- <h4>Add Inquiries in Bulk</h4> -->
      <b-card>
      <b-row>
        <b-col md="4"><b-form-file type="file" accept=".csv" @change="onFileChange"/></b-col>
        <b-col md="4"><b-button variant="outline-primary" @click="uploadFile">Add Bulk Inquiries</b-button></b-col>
        <b-col md="4"><router-link :to="{name: 'infra-add-inquiry'}"><b-button variant="outline-primary" >Add Inquiry</b-button></router-link></b-col>
      </b-row>
      </b-card>
        <span v-if="bulkUploadError" class="text-danger mb-1">{{ bulkUploadError }}</span>
      <b-card>
      <b-row class="mt-1">
        <b-col md="12">
          <div class="mb-4">
          <h3 class="text-center text-primary" style="float: left;">Inquiry List</h3>
          <b-button variant="outline-primary" class="align-right" v-if="infraInquiryList.length > 0"
          style="float: right;"
                    @click="exportToExcel('inquiry-list')"
                    > Download Inquiry List<feather-icon
                        icon="ArrowDownIcon"
                        size="16"
                    /></b-button>
          </div>          
          <div class="table-responsive mt-1" v-if="infraInquiryList.length > 0">
            <table class="table" id="inquiry-list" >
              <thead>
                <tr style="background-color: yellow">
                  <th>Actions</th>
                  <th>Inquiry Id</th>
                  <th>InquiryDate</th>
                  <th>Requirement Date</th>
                  <th>Client Name</th>
                  <th>Project Location</th>
                  <th>Business Sector</th>
                  <th>Commercial Model</th>
                  <th>Status</th>
                  <th>Position/Requirement</th>
                  <th>Quantum</th>
                  <th>Experience</th>
                  <th>Qualification</th>
                  <th>Educational Specification</th>
                  <th>Job Description</th>
                  <th>TAT Deputation</th>
                  <th>Project Duration</th>
                  <th>Expected CTC</th>
                  <th>Current CTC</th>
                  <th>HR Client</th>
                  <th>Office</th>
                  <th>Mobile</th>
                  <th>SPOC Client</th>
                  <th>Office</th>
                  <th>Mobile</th>
                  <th>SPOC Email</th>
                  <th>Working Days</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="entry in infraInquiryList">
                  <tr v-for="(position, index) in entry.infra_position_inquiries" :key="`entry-${entry.inquiry_id}-position-${index}`">
                    <!-- <td v-if="index === 0" :rowspan="entry.positions.length">{{ entry.client_name }}</td>
                    <td v-if="index === 0" :rowspan="entry.positions.length">{{ entry.project_location }}</td> -->
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">
                      <router-link :to="{path: 'inquiry/edit/' + encodeURIComponent(entry.inquiry_id) }">
                        <div class="demo-inline-spacing">
                          <feather-icon size="18" icon="EditIcon" class="text-info" v-b-tooltip.hover.bottom="'Update Position'"/>
                        </div>
                      </router-link>
                    </td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length"><router-link :to="{path: '/infra/tracker/' + encodeURIComponent(entry.inquiry_id), 
                      query: {inquiry_data : JSON.stringify(entry)}}">{{ entry.inquiry_id }}</router-link></td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">{{ formatDate(entry.inquiry_date) }}</td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">{{ formatDate(entry.requirement_date) }}</td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">{{ entry.client_name }}</td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">{{ entry.project_location }}</td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">{{ entry.business_sector }}</td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">{{ entry.commercial_model }}</td>
                    <td v-if="index === 0" :rowspan="entry.infra_position_inquiries.length">{{ entry.status }}</td>
                    <!-- Other columns with rowspan, up to the first six total -->
                    <td>{{ position.position }}</td>
                    <td>{{ position.quantum }}</td>
                    <td>{{ position.experience }}</td>
                    <td>{{ position.qualification }}</td>
                    <td>{{ position.educational_specification }}</td>
                    <td>{{ position.job_description }}</td>
                    <td>{{ position.tat_deputation }}</td>
                    <td>{{ position.project_duration }}</td>
                    <td>{{ position.salary_survey_exp_ctc }}</td>
                    <td>{{ position.salary_survey_curr_ctc }}</td>
                    <td>{{ position.hr_client }}</td>
                    <td>{{ position.hr_contact_office }}</td>
                    <td>{{ position.hr_contact_mobile }}</td>
                    <td>{{ position.spoc_client }}</td>
                    <td>{{ position.spoc_contact_mobile }}</td>
                    <td>{{ position.spoc_contact_mobile }}</td>
                    <td>{{ position.spoc_email }}</td>
                    <td>{{ position.working_days }}</td>
                    <!-- Render additional details for each position here -->
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-else>
            <span class="text-danger"> No Inquiries has been added yet </span>
          </div>
        </b-col>
      </b-row>    
    </b-card>
  </div>
</template>

<script>
// import BEditableTable from "bootstrap-vue-editable-table";

import InfraInquiryService from "@/services/infra-inquiry-service"

import {
  
  BButton,
  BFormFile,
  BTableSimple,
  BRow,
  BCol,
  BTr, BTd, BTh, BTbody, BThead, BTfoot, BCard, VBTooltip
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    data() {
      return {
        infraInquiryList: [],
        selectedFile: null,
        perPage: 50,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        
        fields: [
            { key: 'actions', label: 'Actions', sortable: true},
            { key: 'inquiry_id', label: 'Inquiry Id', sortable: true},
            { key: 'inquiry_date', label: 'Inquiry Date', sortable: true},
            { key: 'requirement_date', label: 'Requirement Date', sortable: true},
            { key: 'client_name', label: 'Client Name', sortable: true},
            { key: 'project_location', label: 'Project Location', sortable: true},
            { key: 'business_sector', label: 'Business Sector', sortable: true},
            { key: 'commercial_model', label: 'Commercial Model', sortable: true},
            { key: 'status', label: 'Status', sortable: true},
            { key: 'position', label: 'Position/Requirement', sortable: true},
            { key: 'quantum', label: 'Quantum', sortable: true},
            { key: 'experience', label: 'Experience', sortable: true},
            { key: 'qualification', label: 'Qualification', sortable: true},
            { key: 'educational_specification', label: 'Educational Specification', sortable: true},
            { key: 'job_description', label: 'Job Description', sortable: true},
            { key: 'tat_deputation', label: 'TAT Deputation', sortable: true},
            { key: 'project_duration', label: 'Project Duration', sortable: true},
            { key: 'salary_survey_exp_ctc', label: 'Expected CTC', sortable: true},
            { key: 'salary_survey_curr_ctc', label: 'Current CTC', sortable: true},
            { key: 'hr_client', label: 'HR Client', sortable: true},
            { key: 'hr_contact_office', label: 'Office', sortable: true},
            { key: 'hr_contact_mobile', label: 'Mobile', sortable: true},
            { key: 'spoc_client', label: 'SPOC Client', sortable: true},
            { key: 'spoc_contact_office', label: 'Office', sortable: true},
            { key: 'spoc_contact_mobile', label: 'Mobile', sortable: true},
            { key: 'spoc_email', label: 'SPOC Email', sortable: true},
            { key: 'working_days', label: 'Working Days', sortable: true},
        ],
        bulkUploadError: null
      }
    },
    components: {
      BButton,
      BFormFile,
      BTableSimple,
      BRow,
      BCol,
      BTr, BTd, BTh, BThead, BTfoot, BCard
    },
    directives: {
      "b-tooltip": VBTooltip
    },
    computed: {
        
    },
    watch: {
        
    },
    created() {

    },
    methods: {
      onFileChange(event) {
        this.selectedFile = event.target.files[0];
        console.log('file', this.selectedFile);
        this.isCsvFile( this.selectedFile.name)
        this.bulkUploadError = null
      },
      getInquiries() {
        InfraInquiryService.getAllInquiries().then(res => {
          console.log(res)
          this.infraInquiryList = res.data
        })
      },
      async uploadFile() {
        this.bulkUploadError = null
        if (!this.selectedFile) {
          alert('Please select a file.');
          return;
        }
        let formData = new FormData();
        formData.append('file', this.selectedFile);
        InfraInquiryService.bulkCreate(formData).then(res =>{

          console.log(res)
          if(res && res.status === 500) {
            this.bulkUploadError = res.data.message
          }
          if(res && res.status === 201) {
            this.getInquiries()
            this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Inquiry List Importes Successfully",
                      icon: "EditIcon",
                      variant: "success",
                    },
                });
          }

        })
        // try {
        //   const response = await this.$axios.post('/api/upload', formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     }
        //   });
        //   console.log('File uploaded successfully', response.data);
        // } catch (error) {
        //   console.error('Error uploading file', error.response);
        // }
      },
      // addSingle() {

      // },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      isCsvFile(filename) {
        const parts = filename.split('.');
        const extension = parts.pop().toLowerCase();
        if(extension !== 'csv') {
          alert(`${filename} is not a csv file.`);
        }
        //return extension === 'csv';
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        
        const day = date.getUTCDate().toString().padStart(2, '0');  // Ensure the day is two digits
        const year = date.getUTCFullYear();  // Full four digit year

        // Array of month names as abbreviations
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[date.getUTCMonth()];  // Get month as an abbreviation

        return `${day}-${month}-${year}`;  // Format the date as "dd-Mon-yyyy"
      },
      updatePosition(id) {

      },
      removePosition(id) {

      },
      exportToExcel(id) {
        const table = document.getElementById(id);
        const worksheet = 'Inquiry List';
        const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
            <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head>
            <body><table>${table.innerHTML}</table></body>
        </html>`;

        const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)));
        const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);

        const ctx = { worksheet, table: table.innerHTML };
        const uri = 'data:application/vnd.ms-excel;base64,' + base64(format(template, ctx));

        //Create a hidden anchor element and trigger the download
        const link = document.createElement('a');
        link.href = uri;
        link.download = 'Inquiry List.xls'; // Change the filename if needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

      // Example usage
      // const isoDateString = "2024-03-12T18:30:00.000Z";
      // console.log(formatDate(isoDateString));  // Outputs: "12-Mar-2024"

    },
    updated: function () {
        
    },
    mounted() {
      this.getInquiries()
    }
}
</script>
<style lang="scss" scoped>
.table-responsive {
  overflow-x: auto;
}
table, th, td {
  border: 1px solid black !important;
  border-collapse: collapse;
} 
th {
  background-color: #ffeca0 !important;
  // color: #fff;
}

</style>